<template>
  <div class="box">
    <nav-router :routerList="useRouterList">
      <img :src="icon" alt="" />
    </nav-router>
    <div class="content">
      <div class="subject-list">
        <div
          class="subject-item"
          v-for="item in subjectList"
          :key="item.id"
          @click="handleClick(item)"
        >
          <div class="subject-cover">
            <img :src="`${OSS_URL}/${item.picture}`" alt="" />
          </div>
          <div class="subject-introduction">
            <span class="subject-name">{{ item.name }}</span>
            <span class="subject-total" v-if="!item.externalLinksUrl">
              共{{ item.contentCount }}个
            </span>
          </div>
        </div>
      </div>
      <!-- 隐藏音乐播放按钮 原因：不确定以后需不需要-->
      <!-- <div class="btns">
        <div v-if="showMusicButton == true">
          <img :src="audio" alt="声音" @click="handleMusic(music)" />
        </div>
      </div> -->
    </div>
    <menu-pop :activeItem="3"></menu-pop>
    <!-- <back :pageDepth="3"></back> -->
    <div class="back-group">
      <div class="btn-group">
        <div v-if="showMore"
          @click="$router.push({ path: '/layout/index' })"
        >
          <img src="@/assets/images/index/home-gray.png" alt="" />
        </div>
        <div v-if="showMore" @click="handleBack">
          <img src="@/assets/images/index/back-gray.png" alt="" />
        </div>
        <div v-if="showMore" @click="showMore = !showMore">
          <img src="@/assets/images/index/close-gray.png" alt="" />
        </div>
        <div v-if="!showMore" @click="showMore = !showMore">
          <img src="@/assets/images/index/open-gray.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import menuPop from '@/components/menuPop'
import navRouter from '@/components/navRouter'
import { API_MODULE } from '@/request/api'
const redIcon = require('@/assets/images/dangHistory/home.png')
const blueIcon = require('@/assets/images/dangHistory/home-blue.png')
import { mapGetters, mapMutations } from 'vuex'
import { openUrl } from '@/utils/windowOpen'
const redAudio = require('@/assets/images/photo/audio.png')
const blueAudio = require('@/assets/images/photo/audio-blue.png')
const redPauseAudio = require('@/assets/images/photo/audio-pause.png')
const bluePauseAudio = require('@/assets/images/photo/audio-pause-blue.png')
export default {
  name: 'CustomizeCategory',
  inject: ['reload'],
  data() {
    return {
      audio: redPauseAudio,
      icon: redIcon,
      subjectList: [],
      // music: "",
      // 是否显示播放按钮
      // showMusicButton: true,
      isPause: false,
      showMore: false
    }
  },
  computed: {
    ...mapGetters('skin', ['useSubject', 'useRouterList'])
  },
  created() {
    console.log('我进来了')
    if (this.useSubject === 'red') {
      this.icon = redIcon
      this.audio = redPauseAudio
    } else {
      this.icon = blueIcon
      this.audio = bluePauseAudio
    }
    // this.music = this.$route.query.music
    // if (this.music == null || this.music == undefined || this.music == "") {
    //   this.showMusicButton = false
    // } else {
    //   this.showMusicButton = true
    // }
    this.getData()
    this.useRouterList.find((router, i) => {
      if (!router) return
      if (router.path === this.$route.fullPath) {
        this.useRouterList.splice(i + 1)
      }
    })
    this.setRouterList(this.useRouterList, this.$route.fullPath)
  },
  watch: {
    useSubject(val) {
      if (val === 'red') {
        this.icon = redIcon
      } else {
        this.icon = blueIcon
      }
    },
    $route: function () {
      this.getData()
    },
    isPause(val) {
      if (this.useSubject === 'red') {
        this.audio = val ? redAudio : redPauseAudio
      } else {
        this.audio = val ? blueAudio : bluePauseAudio
      }
    }
  },
  methods: {
    ...mapMutations('skin', ['setRouterList']),
    getData() {
      this.POST(API_MODULE.SEARCH_CATEGORY_OR_CONTENT_BY_USER, {
        catId: [this.$route.query.id]
      })
        .then((res) => {
          if (res.code == 0 || res.code == 200) {
            this.subjectList = res.data.model
          } else {
            this.$message(res.msg)
          }
        })
        .catch((err) => {
          this.$message.error(err.msg)
        })
    },

    handleClick(item) {
      if (item.externalLinksUrl) {
        openUrl(item.externalLinksUrl)
      } else {
        this.POST(API_MODULE.SEARCH_CATEGORY_OR_CONTENT_BY_USER, {
          catId: [item.id]
        })
          .then((res) => {
            if (res.code == 0 || res.code == 200) {
              this.$router.push({
                path: `/layout/customize/${res.data.type}`,
                query: {
                  id: item.id
                }
              })

              // 保存下一级路径
              const path = `/layout/customize/${res.data.type}?id=${item.id}`
              this.useRouterList.push({
                name: item.name,
                path: path
              })
              this.setRouterList(this.useRouterList, path)
            } else {
              this.$message(res.msg)
            }
          })
          .catch((err) => {
            this.$message.error(err.msg)
          })
      }
    },

    handleMusic(src) {
      this.isPause = !this.isPause
      if (src) {
        this.playOrPausedAudio(this.OSS_URL + src)
      } else {
        this.$message({ message: '请联系管理员上传音频', type: 'error' })
      }
    },
    handleBack(){
        this.$router.replace({ path: this.useRouterList[this.useRouterList.length - 2].path })
        this.reload()
    }
  },
  components: {
    menuPop,
    navRouter
  }
}
</script>

<style scoped lang="scss">
@import "@/themes/_handle.scss";

.box {
  .content {
    min-height: 13rem;
    padding: 0.6rem 0 0.6rem 0;
    box-sizing: border-box;
    @include background_color("background-medium");

    .title {
      font-size: 0.74rem;
      line-height: 0.74rem;
      font-weight: 400;
      color: #ffffff;
      margin-top: 1.3rem;
      text-align: center;
      margin-bottom: 0.4rem;
    }

    .sub-title {
      font-size: 0.37rem;
      line-height: 0.37rem;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
    }

    .subject-list {
      display: flex;
      flex-wrap: wrap;
      padding-left: 2.76rem;

      .subject-item {
        margin-right: 1.6rem;
        margin-top: 1.26rem;
        position: relative;

        .subject-cover {
          width: 5.62rem;
          height: 5.38rem;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .subject-introduction {
          padding: 0 0.4rem;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 1.6rem;
          background-color: rgba(204, 167, 102, 0.6);
          display: flex;
          align-items: center;
          justify-content: space-between;

          .subject-name {
            width: 3.6rem;
            overflow: hidden;
            /*文本不会换行*/
            white-space: nowrap;
            /*当文本溢出包含元素时，以省略号表示超出的文本*/
            text-overflow: ellipsis;
            font-size: 0.36rem;
            font-weight: 600;
            color: #ffffff;
            opacity: 1;
            text-align: left;
          }

          .subject-total {
            font-size: 0.24rem;
            color: #ffffff;
            opacity: 1;
          }
        }
      }
    }

    .btns {
      position: fixed;
      top: 2rem;
      right: 0.68rem;

      div {
        margin-bottom: 0.6rem;

        img {
          width: 0.8rem;
        }
      }
    }
  }
}

.back-group {
  z-index: 3005;
  position: fixed;
  right: 0.6rem;
  bottom: 0.8rem;
  /*opacity: 0.8;*/
  .btn-group {
    div {
      margin-bottom: 0.1rem;
    }
  }
  img {
    width: 1.2rem;
  }
}
</style>
